import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/esm/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const WhyChooseUsCard = (props) => {
  return (
    <Col xs={12} md={4} lg={4} xl={4}>
        <Card className='m-2 container-leaf-light shadow-effect'>
            <Card.Body>
            <FontAwesomeIcon icon={faCircleCheck} size="2x" className="m-2 float-end text-green" />
                <Card.Title className='text-center'>{props.title} </Card.Title>
            </Card.Body>
        </Card>
    </Col>
  );
}

export default WhyChooseUsCard;