
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Gallery from "./pages/Gallery";
import Login from "./pages/Login";
import Services from './pages/Services';
import './sass/main.scss';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact index element={<Home />} />
          <Route exact path="gallery" element={<Gallery />} />
          <Route exact path="contact" element={<Contact />} />
          <Route exact path='services' element={<Services />} />
          <Route exact path="login" element={<Login />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
