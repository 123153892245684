import logo from '../images/color-logo-no-bg.png'

import Container from 'react-bootstrap/Container';
import ContactUsForm from '../components/ContactUsForm';


const Contact = () => {
    return (
      <>
        <Container className="color-primary mt-2 mb-2 text-center p-2 rounded shadow-effect">
          <h1>Contact Us</h1>
        </Container>
        <Container className="color-primary mt-2 mb-2 text-center p-2 rounded shadow-effect">
          {/* <img src={logo} class="img-fluid"></img> */}
          <ContactUsForm />
        </Container>
      </>
      
    );
}
  
export default Contact;