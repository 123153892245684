import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { faFacebook, faInstagram, faLinkedin, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSquarePhoneFlip } from '@fortawesome/free-solid-svg-icons';


function Footer() {
  return (
    <footer className="color-secondary  text-center">
      <div className="container p-5">
        <div className="row ">
          <div className="col-md-4">
            <h4>About Us</h4>
            <p>At The Landscape King, we're passionate about creating beautiful outdoor spaces that enhance the beauty and functionality of your property. As a specialist in groundworks, we have the expertise and equipment needed to tackle any landscaping project, no matter how complex.</p>
          </div>
          <div className="col-md-4">
            <h4>Quick Links</h4>
            <ul className="list-unstyled">
              <li><a href="/">Home</a></li>
              <li><a href="/gallery">Gallery</a></li>
              <li><a href="/contact">Contact Us</a></li>
              <li><a href="/services">Services</a></li>
            </ul>
            <a href='https://m.facebook.com/100086124387680/'><FontAwesomeIcon icon={faFacebook} size="2x" className="m-1" /></a>
            <a href='mailto: harry.fisher@hotmail.co.uk'><FontAwesomeIcon icon={faEnvelope} size="2x" className="m-1" /></a>
            <a href='tel:+44 7377 348634'><FontAwesomeIcon icon={faSquarePhoneFlip} size="2x" className="m-1" /></a>
          </div>
          <div className="col-md-4">
            <h4>Contact Us</h4>
            <ul className="list-unstyled">
              <li><i className="fa fa-map-marker"></i>Willow Barn , Meadow Lane, Yoxall, DE13 8FJ</li>
              <li><i className="fa fa-envelope"></i>harry.fisher@hotmail.co.uk</li>
              <li><i className="fa fa-phone"></i>(+44) 7377 348634</li>
            </ul>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80">
        <path fill="#152354" fill-opacity="1" d="M0,40L80,36C160,32,320,24,480,28C640,32,800,48,960,46.675C1120,45.35,1280,26.75,1360,17.325L1440,8L1440,80L1360,80C1280,80,1120,80,960,80C800,80,640,80,480,80C320,80,160,80,80,80L0,80Z"></path>
      </svg>

      <div className="container-fluid color-primary py-3">
        <div className="container text-center">
          <p className="text-muted mb-0">Copyright &copy; 2023, The Landscape King
            <br/>
            <a href="#">Privacy Policy</a> | <a href="#">Terms of Use</a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;