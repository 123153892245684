import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import picture1 from '../images/tile-paving.jpeg'
import picture2 from '../images/garden-hedge.jpeg'
import picture3 from '../images/pateo.jpeg'
import picture4 from '../images/boxing.jpeg'
import picture5 from '../images/fencing.jpeg'
import Col from 'react-bootstrap/esm/Col';

const Gallery = () => {
    return (
      <>
        <Container className="color-primary mt-2 mb-2 text-center p-2 rounded shadow-effect">
          <h1>Gallery</h1>
          
        </Container>

        <Container>
          <Row className='p-2'>
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
              <Image src={picture1} responsive thumbnail className='mt-2 shadow-effect' />
            </Col>
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
              <Image src={picture2} responsive thumbnail className='mt-2 shadow-effect' />
            </Col>
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
              <Image src={picture3} responsive thumbnail className='mt-2 shadow-effect' />
            </Col>
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
              <Image src={picture4} responsive thumbnail className='mt-2 shadow-effect' />
            </Col>
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
              <Image src={picture5} responsive thumbnail className='mt-3 shadow-effect' />
            </Col>
          </Row>
        </Container>
          
        
      </>
    
    );
}
  
export default Gallery;