import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function BasicExample() {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: ''
    });
  
    const [errors, setErrors] = useState({});
  
    const handleSubmit = (event) => {
      event.preventDefault(); // prevent page reload on form submission
      console.log(formData); // log form data to the console (for testing purposes)
  
      // Validation logic
      const errors = {};
      if (!formData.name) {
        errors.name = 'Name is required';
      }
      if (!formData.email) {
        errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = 'Invalid email address';
      }
      if (!formData.phone) {
        errors.phone = 'Phone number is required';
      } else if (!/^[0-9]{10}$/.test(formData.phone)) {
        errors.phone = 'Invalid phone number';
      }
      if (!formData.message) {
        errors.message = 'Message is required';
      }
  
      if (Object.keys(errors).length === 0) {
        // Add your form submission logic here (e.g. submit data to server)
        console.log('Form submitted successfully');
      } else {
        setErrors(errors);
      }
    }
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  
    return (
      <Form className='p-5' onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Your Name</Form.Label>
          <Form.Control type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleInputChange} />
          {errors.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" name="email" placeholder="Enter email" value={formData.email} onChange={handleInputChange} />
          {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Phone</Form.Label>
          <Form.Control type="tel" name="phone" placeholder="Enter your phone number" value={formData.phone} onChange={handleInputChange} />
          {errors.phone && <Form.Text className="text-danger">{errors.phone}</Form.Text>}
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" name="message" rows={3} value={formData.message} onChange={handleInputChange} />
          {errors.message && <Form.Text className="text-danger">{errors.message}</Form.Text>}
        </Form.Group>
  
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    );
  }
  

export default BasicExample;
