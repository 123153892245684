import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import HomePageCarousel from '../components/HomePageCarousel';
import HomeServiceCard from '../components/HomeServiceCard';
import WhyChooseUsCard from '../components/WhyChooseUsCard';


const Home = () => {
    return (
      <Container fluid >
        <Container fluid className='container-leaf-light p-2'>
          <HomePageCarousel />
        </Container>

          <Container >
            <Row className='text-center p-4' >
              <h1>Our <span className='text-green'>Services</span></h1> 
              <WhyChooseUsCard title='Groundwork Specialists' text='Test2' />
              <WhyChooseUsCard title='Fully Insured' text='Our landscapers are fully insured for their safety & your piece of mind.' />
              <WhyChooseUsCard title='Multiple Payment Options' text='We accept Direct Deposit, Credit Card and Cash. Click here for more details.' />
              <WhyChooseUsCard title='Free Consultations' text='We offer free consultations for our services, with no obligations.' />
              <WhyChooseUsCard title='Reputable Business' text='We have a reputation for service & quality work, testimonials available upon request.' />
              <WhyChooseUsCard title='Quality Guarantee' text='Test2' />
            </Row>
          </Container>

          <Container fluid className='container-leaf '>
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 160">
            <path fill="#fff" fill-opacity="1" d="M0,144L80,141.35C160,138.7,320,133.5,480,117.35C640,101.2,800,74.5,960,74.65C1120,74.8,1280,101.5,1360,114.65L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg> */}
            <Container className=''>
              <Row className="text-center p-5">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className='p-1'>
                    <h1 className='text-green'><span className='text-white'>About</span> Our Business</h1>
                    <hr></hr>
                    <p> At The Landscape King, we're passionate about creating beautiful outdoor spaces that enhance the beauty and functionality of your property. As a specialist in groundworks, we have the expertise and equipment needed to tackle any landscaping project, no matter how complex.<br/><br/>

                        We take pride in our attention to detail and our commitment to quality workmanship. Whether you're looking to create a new patio, install a garden pond, or build a retaining wall, we'll work closely with you to understand your vision and bring it to life. From the initial design stage to the final touches, we'll be there every step of the way to ensure that your project meets your expectations and exceeds your wildest dreams.<br/><br/>

                        At The Landscape King, we believe that communication is key to a successful landscaping project. That's why we'll keep you informed throughout the process, answering any questions you may have and providing regular updates on our progress. We'll also work closely with you to ensure that our work is in line with your budget and timeline.<br/><br/>

                        With years of experience in the landscaping industry and a team of skilled professionals, we're confident that we have what it takes to transform your outdoor space into something truly special. Contact us today to learn more about our services and how we can help you create the landscape of your dreams.<br/><br/>
                    </p>
                    <a href='/services'>Click here to learn more</a>
                  </div>
                </Col>
                {/* <Col>
                  <div className='panel-border p-5'>
                    <h1 className='text-green'><span className='text-white'>Request</span> A FREE Quote</h1>
                    <hr></hr>
                  </div>
                </Col> */}
              </Row>
            </Container>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 160">
                <path fill="#fff" fill-opacity="1" d="M0,80L80,72C160,64,320,48,480,56C640,64,800,96,960,93.35C1120,90.7,1280,53.5,1360,34.65L1440,16L1440,160L1360,160C1280,160,1120,160,960,160C800,160,640,160,480,160C320,160,160,160,80,160L0,160Z"></path>
            </svg> */}
          </Container>

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9658.59366080891!2d-1.7920018562423505!3d52.75657787775335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487a06163aea08b5%3A0x5a92be418190da3a!2sBurton-on-Trent%20DE13%208FJ!5e0!3m2!1sen!2suk!4v1680543031614!5m2!1sen!2suk" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      
      </Container>
    );
}
  
export default Home;