import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../images/logo.png'
import { faFacebook, faInstagram, faLinkedin, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSquarePhoneFlip } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

const Navigation = () => {
  return (

      <>
        
        
        <Navbar collapseOnSelect expand="lg" sticky="top" className='color-primary text-center'>
  <Container>
    <Navbar.Brand href="/" className="p-0">
      <div className="d-flex align-items-center">
        <img id='logo' alt='yoxall_landscaping_logo' src={logo}></img>
      </div>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="justify-content-center mx-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="gallery">Gallery</Nav.Link>
        <Nav.Link href="contact">Contact Us</Nav.Link>
        <Nav.Link href="services">Services</Nav.Link>
      </Nav>
      <Nav className="ml-auto">
        <a className='' href='https://m.facebook.com/100086124387680/'><FontAwesomeIcon icon={faFacebook} size="2x" className="m-2" /></a>
        <a className='' href='mailto: harry.fisher@hotmail.co.uk'><FontAwesomeIcon icon={faEnvelope} size="2x" className="m-2" /></a>
        <a className='' href='tel:+44 7377 348634'><FontAwesomeIcon icon={faSquarePhoneFlip} size="2x" className="m-2" /></a>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>


      </>
    
    
  );
}

export default Navigation;

{/* <>
        <div class="text-center container-fluid color-primary">
          <img id='logo' alt='yoxall_landscaping_logo' className='img-fluid' src={logo}></img>
        </div>
        <Navbar collapseOnSelect expand="lg" sticky="top" className='main-nav '>
          <Container>
            <Navbar.Brand href="/"></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="text-center">
                <Nav.Link className='text-white' href="/">Home</Nav.Link>
                <Nav.Link className='text-white' href="gallery">Gallery</Nav.Link>
                <Nav.Link className='text-white' href="contact">Contact</Nav.Link>
                <NavDropdown title="Services" id="nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Service 1</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">service 2</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Service 3</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </> */}