import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import picture1 from '../images/pateo.jpeg';


const Services = () => {
    return (
      <>
        <Container className="color-primary mt-2 mb-2 text-center p-2 rounded shadow-effect">
          <h1>Services</h1>
        </Container>
        <Container className="mt-2 mb-2 p-5">
          <p className='h-5'>We are your local landscape and groundwork specialists, providing a comprehensive range of services to enhance your outdoor living space. Our team is highly experienced and skilled in delivering quality solutions for all your landscaping needs.</p>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className='color-primary shadow rounded p-3'>
              <ul className=''>
                <li>
                  <h5>Patios</h5>
                </li>
                <li>
                  <h5>Drainage</h5>
                </li>

                <li>
                  <h5>Driveways</h5>
                </li>

                <li>
                  <h5>Gateways/Entrances</h5>
                </li>

                <li>
                  <h5>Concreting</h5>
                </li>

                <li>
                  <h5>Full Garden Transformation</h5>
                </li>

                <li>
                  <h5>Sleeper Boxes</h5>
                </li>

                <li>
                  <h5>Kurbs</h5>
                </li>

                <li>
                  <h5>Fencing and Garden Gates</h5>
                </li>

                <li>
                  <h5>Digger/Machine Work</h5>
                </li>

                <li>
                  <h5>Stable Blocks</h5>
                </li>

                <li>
                  <h5>Underpinning Houses</h5>
                </li>
                <li>
                  <h5>Water Pipes and Drainage</h5> 
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Image src={picture1} responsive thumbnail className='mt-2 shadow-effect' />
            </Col>
          </Row>  
        </Container>
      </>
    );
}
  
export default Services;